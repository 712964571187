export const mineConfig = [
  {
    field: 'createTime',
    title: '创建时间',
    type: 'date',
    defaultVal: [],
    componentName: 'WeekRangeDate',
    dateFormat: 'YYYY-MM-DD',
    maxRange: ''
  },
]

export const branchConfig = [
  {
    field: 'createStaffId',
    title: '创建人',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'createStaffId'
  },
  {
    field: 'orgId',
    title: '部门',
    type: 'string',
    defaultVal: '',
    componentName: 'GoTree',
    treeType: 'orgId'
  },
  {
    field: 'createTime',
    title: '创建时间',
    type: 'date',
    defaultVal: [],
    componentName: 'WeekRangeDate',
    dateFormat: 'YYYY-MM-DD',
    maxRange: ''
  },
]

