<template>
  <div
    ref="cardBox"
    class="card-box"
  >
    <div class="card-cont">
      <div class="card-imgbox">
        <!--        <i-->
        <!--          v-if="data.readFlag"-->
        <!--          class="card-mark"-->
        <!--        />-->
        <div class="card-img">
          <img
            :src="imgUrl"
            alt=""
          >
        </div>
      </div>
      <div class="card-content">
        <div class="card-a">
          <div class="card-aa">
            <span class="card-aa-name">{{ data.title }}</span>
            <span class="card-aa-company">{{ data.orgName }}</span>
          </div>
          <slot>
            <div
              v-if="data.readFlag === ''"
              class="card-status"
              @click.stop="checkReport"
            >
              <span class="card-status-name">{{ data.statusName }}</span>
              <i class="card-status-arrows" />
            </div>
            <div
              v-else
              class="card-status-read"
              :class="data.readFlag === 1 ? 'readedBg' : 'unReadBg'"
            >
              <span class="card-status-name">{{ data.readFlag === 1 ? '已读' : '未读' }}</span>
            </div>
          </slot>
        </div>
        <div class="card-c">
          <span
            v-if="data.createTime"
            class="card-c-l"
          >
            <!--            <i class="icon-joyo card-c-icon">&#xe625;</i>-->
            <i class="card-c-read">{{ data.createTime }}</i>
          </span>
          <!--          <span-->
          <!--            v-if="data.totalComment === 0"-->
          <!--            class="card-c-r"-->
          <!--          >-->
          <!--            <i class="icon-joyo card-c-icon">&#xe626;</i>-->
          <!--            <i class="card-c-read">{{ `${data.readComment}条评论` }}</i>-->
          <!--          </span>-->
          <!--          <span-->
          <!--            v-else-->
          <!--            class="card-c-r"-->
          <!--          >-->
          <!--            <i-->
          <!--              v-if="data.readComment"-->
          <!--              class="icon-joyo card-c-icon"-->
          <!--            >&#xe626;</i>-->
          <!--            <i-->
          <!--              v-if="data.readComment"-->
          <!--              class="card-c-read"-->
          <!--            >{{ `${data.readComment}条已读` }}</i>-->
          <!--            <i v-if="data.readComment && data.unReadComment">，</i>-->
          <!--            <i-->
          <!--              v-if="data.unReadComment"-->
          <!--              class="card-c-unread"-->
          <!--            >{{ `${data.unReadComment}条未读` }}</i>-->
          <!--          </span>-->
        </div>
      </div>
    </div>
    <div
      v-if="data.sumUpExperience"
      class="card-b"
    >
      {{ data.sumUpExperience }}
    </div>
    <div class="comment-footer">
      <span
        v-if="data.totalComment === 0"
        class="card-c-r"
      >
        <i class="icon-joyo card-c-icon">&#xe626;</i>
        <i class="card-c-read">{{ `${data.readComment}条评论` }}</i>
      </span>
      <span
        v-else
        class="card-c-r"
      >
        <i
          v-if="data.readComment"
          class="icon-joyo card-c-icon"
        >&#xe626;</i>
        <i
          v-if="data.readComment"
          class="card-c-read"
        >{{ `${data.readComment}条已读` }}</i>
        <i
          v-if="data.readComment && data.unReadComment"
          style="color: #999"
        >，</i>
        <i
          v-if="data.unReadComment"
          class="card-c-unread"
        >{{ `${data.unReadComment}条未读` }}</i>
      </span>
    </div>
  </div>
</template>

<script>
import { verdict } from '@/lib/until.js';
import { matchingColor } from '@/lib/until';

export default {
  props: {
    imgUrl: {
      type: String,
      default: require('../../assets/img/person.png')
    },
    data: {
      type: Object,
      default() {
        return {};
      }
    },
    statusType: {
      type: String,
      default: 'status'
    }
  },
  created() {
    console.log(this.data, 'data');
  },
  methods: {
    verdicts(str) {
      return verdict(str);
    },
    matchColor(val) {
      return matchingColor(val);
    },
    checkReport() {
      let checkReport = 'checkReport';
      this.$emit(checkReport, this.data.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  // height: 155px;
  // margin-top: 10px;
  background-color: $color-FFF;
  .card-cont {
    display: flex;
    justify-content: flex-start;
    padding: 15px 15px 10px 15px;
    .card-imgbox {
      position: relative;
      width: 40px;
      height: 40px;
      margin-right: 12px;
      background-color: $color-01;
      border-radius: 50%;
      img {
        width: 40px;
        border-radius: 50%;
      }
      .card-mark {
        position: absolute;
        top:0;
        right: 0;
        width: 10px;
        height: 10px;
        background: #F33509;
        border-radius: 50%;
      }
    }
    .card-content {
      flex: 1;
      text-align: left;
      .card-a {
        display: flex;
        justify-content: space-between;
        .card-aa {
          .card-aa-name {
            margin-right: 4px;
            color: $color-333;
            font-weight: 600;
            font-size: $font-16;
            line-height: 22px;
          }
          .card-aa-company {
            color: $color-999;
            font-size: $font-12;
            line-height: 17px;
          }
        }
        .card-status{
          display: flex;
          justify-content: center;
          width: 72px;
          height: 24px;
          //padding: 2px;
          background-color:$color-01;
          border-radius: 12px;
          @extend .uniline-ellipsis;
          .card-status-name{
            align-self: center;
            color: $color-3FF;
            font-size: $font-12;
            line-height: 17px;
          }
          .card-status-arrows{
            align-self: center;
            width:6px;
            height: 10px;
            margin-left: 2px;
            background-image: url('../../assets/img/new_arrows.png');
            background-size: 6px 10px;
          }
        }
        .card-status-read{
          display: flex;
          justify-content: center;
          width: 40px;
          height: 24px;
          border-radius: 12px;
          .card-status-name{
            align-self: center;
            font-size: $font-12;
            line-height: 17px;
          }
        }
        .readedBg{
          color: #999;
          background: #F7F7F7;
        }
        .unReadBg{
          color: #00A4FF;
          background: #E5F5FF;
        }
        .card-time {
          flex: 0 0 96px;
          align-self: center;
          color: $color-333;
          font-size: $font-12;
          line-height: 17px;
          text-align: right;
        }
      }
      .card-c{
        font-size: $font-12;
        line-height: 17px;
        .card-c-r{
          margin-left: 16px;
        }
        .card-c-icon{
          margin-right: 4px;
          color: $color-999;
          font-size: $font-10;
        }
        .card-c-read{
          color: $color-999;
        }
        .card-c-unread{
          color: #F7B321;
          font-weight: 400;
        }
      }
      .card-d {
        display: flex;
        justify-content: space-between;
        margin-top:15px;
        border-top: 1px solid $color-E5;
        .card-da {
          padding-top: 15px;
          color: $color-999;
          font-size: $font-14;
        }
        .card-da:last-child {
          padding-right: 18px;
        }
      }
    }
  }
  .card-b {
    //width: 100%;
    padding: 0 15px;
    color: $color-666;
    font-size: $font-14;
    line-height: 22px;
    text-align: left;
    @include show_line(2);
    //@extend .uniline-ellipsis;
  }
  .comment-footer {
    padding: 10px 0;
    font-size: $font-12;
    line-height: 17px;
    text-align: left;
    .card-c-r{
      margin-left: 16px;
    }
    .card-c-icon{
      margin-right: 4px;
      color: $color-999;
      font-size: $font-10;
    }
    .card-c-read{
      color: $color-999;
    }
    .card-c-unread{
      color: #F7B321;
      font-weight: bold;
    }
  }
}
</style>
