<template>
  <div class="section workreport-manage">
    <complex
      ref="complex"
      class="test"
      :offset-top="0"
      :data="tableData[tabItem.code]"
      :load-enable="loadEnable"
      :refresh-enable="loadEnable"
      @pullingUp="onPullingUp"
      @pullingDown="onPullingDown"
      @diffChange="diffChange"
    >
      <template slot="header">
        <div
          v-if="scrollDiff < 1"
          style="height: 8px"
        ></div>
      </template>
      <template slot="sticky-ele">
        <div
          v-if="scrollDiff >= 1"
          style="height: 8px"
        ></div>
        <condition-info
          v-model="conditionValue"
          :tabs="tabList"
          :filter-num="filterNum"
          :fast-list="fastList"
          :is-tab-show="scrollDiff < 1"
          :tab-num-show="true"
          @tabChange="tabChange"
          @fastChange="fastChange"
          @toFilter="toFilter"
        />
      </template>
      <template slot="content">
        <div class="content">
          <template
            v-for="(items, i) in tableData"
          >
            <div
              v-if="tabItem.code === i"
              :key="i"
              class="list-wrapper"
            >
              <error-page
                v-if="!items.length"
                class="errorHeight"
              />
              <ul
                v-for="(item, indexs) in items"
                :key="indexs"
                class="marginBottom10"
                @click="toDetail(item.id, item.mlabel)"
              >
                <div
                  v-if="item.mlabel === '提交给我的'"
                  class="card-time"
                >
                  {{ item.mainObj.week }}
                </div>
                <card-list
                  :data="item.mainObj"
                  :status-type="statusType"
                  @checkReport="checkReport"
                />
              </ul>
            </div>
          </template>
        </div>
      </template>
    </complex>
    <!-- 查看报告人 -->
    <custom-popup
      ref="customPopups"
    >
      <popup-header
        slot="header"
        :title="popTitle"
      />
      <div
        slot="content"
        class="dialog-detail"
      >
        <div
          v-if="primarySender.length"
          class="dialog-content"
        >
          <div class="dialog-content-title">
            主发送人员
          </div>
          <img-view :list="primarySender" />
        </div>
        <div
          v-if="recipientList.length"
          class="dialog-content"
        >
          <div class="dialog-content-title">
            抄送人员
          </div>
          <img-view :list="recipientList" />
        </div>
      </div>
    </custom-popup>
    <filter-model
      :show.sync="filterModelShow"
      :data.sync="filterData"
      :num.sync="filterNum"
      :nodes="modelConfig"
      :dict="dictList"
      @confirm="filterConfirm"
    />
  </div>
</template>

<script >
import { mapGetters, mapMutations } from 'vuex';

import { myWeekReport, unReadNumber, weekReportCommentList, weekReportSubmitToMe } from '_api/workreport';
import complex from '_c/complex'
import conditionInfo from '_c/condition/filter.vue'
import errorPage from '_c/errorPage';
import FilterModel from '_c/fliter-model'
import customPopup from '_c/popup/custom-popup';
import popupHeader from '_c/popup/popup-header';
import cardList from '_c/tab-card/card-list';
import imgView from '_c/view-match/img-view';

import { debounce, findValueByKey, ModalHelper } from '@/lib/until';
import { branchConfig, mineConfig } from './filterConfig'

export default {
  name: 'WorkreportManage',
  components: {
    complex,
    conditionInfo,
    customPopup,
    popupHeader,
    imgView,
    cardList,
    errorPage,
    FilterModel
  },
  data() {
    return {
      aid: [],
      tabList: [],
      requestData: {
        createStaffId: '',
        orgId: '',
        startDate: '',
        endDate: '',
        readFlag: '',
        status: '',
        pageNum: 1,
        pageSize: 10
      },
      tableData: [[], []],
      index: 0,
      needIcon: true,
      popTitle: '',
      primarySender: [],
      recipientList: [],
      account: 0, // 进入次数
      scrollDiff: 0,
      conditionValue: {
        tabItem: { code: 0 },
        fastItem: { code: '' }
      },
      filterModelShow: false,
      filterData: {},
      filterNum: 0,
      // 在路由撤退时用来触发子组件数据更新
      activatedNum: 0
    };
  },
  computed: {
    ...mapGetters('workreport', ['filterObj', 'aidList']),
    ...mapGetters(['userInfo', 'dictList']),
    loadEnable() {
      return this.tableData[this.index] ? !!this.tableData[this.index].length : true;
    },
    statusType() {
      return this.index === 0 ? 'status' : 'time';
    },
    tabItem() {
      return this.conditionValue.tabItem || {}
    },
    fastItem() {
      return this.conditionValue.fastItem || {}
    },
    fastList() {
      const item = this.tabList[this.tabItem.code]
      let list = []
      if (item && (item.label.includes('我的'))) {
        Array.isArray(this.dictList.readFlag) && (list = this.dictList.readFlag.map(item => ({ ...item, label: item.text, code: item.value })))
      } else {
        Array.isArray(this.dictList.status) && (list = this.dictList.status.map(item => ({ ...item, label: item.text, code: item.value })))
      }
      return list
    },
    modelConfig() {
      let config = []
      if (this.isMineTab(this.tabItem.code)) {
        config = mineConfig
      } else {
        config = branchConfig
      }
      return config
    }
  },
  provide() {
    return {
      manageContainer: this
    }
  },
  mounted() {
    this.init();
  },
  activated() {
    this.activatedNum++
  },
  methods: {
    ...mapMutations(['CLEAR_FILTER', 'SET_KEEPALIVE']),
    getLists(requestData, aid, index, mlabel, type) {
      this.account++;
      const request = mlabel === '我提交的' ? myWeekReport : weekReportSubmitToMe;
      request(requestData, aid[index]).then((res) => {
        if (res.flag) {
          const tableData = [[], []];
          const list = res.data.list || [];
          // 如果是第一页的情况才会判断无数据跳入到提交给我的页面
          // if (requestData.pageNum === 1) {
          //   // 在判断是否存在过滤条件 若存在则无需跳转
          //   let flag = false;
          //   for (const key in this.filterObj) {
          //     if (this.filterObj[key].value) {
          //       flag = true;
          //     }
          //   }
          //   if ((mlabel === '我提交的' && list.length === 0 && !flag) && this.account < 3) this.$refs.tabComposite.initialIndex = 1;
          // }
          if (list.length > 0) {
            if (type === 'upload') {
              tableData[index] = this.tableData[index].concat(list);
            } else {
              tableData[index] = list;
            }
            tableData[index].forEach(item => {
              item.mlabel = mlabel;
              item.mainObj = {
                id: item.id,
                readFlag: index === 1 ? item.readFlag : '',
                title: index === 1 ? (item.createName ? item.createName.substring(0, item.createName.indexOf('(')) : '') : item.weekly,
                orgName: item.orgName || '',
                status: item.status,
                statusName: index === 1 ? findValueByKey(this.dictList.readFlag, item.status) : findValueByKey(this.dictList.status, item.status),
                week: item.weekly || '',
                sumUpExperience: item.sumUpExperience || '',
                createTime: item.commitTime ? item.commitTime.substring(0, 16) : '',
                totalComment: item.totalComment,
                readComment: item.readComment,
                unReadComment: item.unReadComment
              };
            });
            this.$set(this.tableData, index, tableData[index]);
            this.tabList[index].tableData = tableData[index];
            if (index === 1) this.getunReadNumber(index);
            if (this.$refs.complex) this.$refs.complex.upload = true;
          } else {
            if (requestData.pageNum === 1) {
              this.$set(this.tableData, index, []);
            } else {
              if (this.$refs.complex) this.$refs.complex.upload = false;
              this.$refs.complex.onPullingUp();
            }
          }
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    // 开启报告联系人弹窗
    checkReport(id) {
      this.popTitle = '报告接收人';
      this.$refs.customPopups.isShow = true;
      this.getWeekReportComment(id);
      ModalHelper('modal-open').afterOpen();
    },
    // 获取接收人
    getWeekReportComment(id) {
      weekReportCommentList({ reportId: id }).then(res => {
        if (res.flag) {
          this.primarySender = [];
          this.recipientList = [];
          res.data.mainList.forEach(item => {
            const itemObj = {
              name: item.name,
              status: item.readFlag === 1 ? '已读' : '未读'
            };
            this.primarySender.push(itemObj);
          });
          res.data.ccList.forEach(item => {
            const itemObj = {
              name: item.name,
              status: item.readFlag === 1 ? '已读' : '未读'
            };
            this.recipientList.push(itemObj);
          });
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    getunReadNumber(index) {
      unReadNumber(this.userInfo.userInfoRes.staffId).then(res => {
        if (res.flag) {
          this.tabList[index].num = res.data;
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    toDetail(id, mlabel) {
      this.SET_KEEPALIVE([`${this.$route.name}`]);
      const index = mlabel === '我提交的' ? '0' : '1';
      this.$router.push(
        {
          name: 'WorkreportDetails',
          params: {
            reportId: id,
            type: index
          }
        }
      );
    },
    toFilter() {
      this.filterModelShow = true
      // this.SET_KEEPALIVE([`${this.$route.name}`]);
      // const index = this.tabList[this.index].label === '我提交的' ? '0' : '1';
      // this.$router.push(
      //   {
      //     name: 'workreport-filter',
      //     params: {
      //       i: index
      //     }
      //   }
      // );
    },
    // toScreen() {
    //   this.tofilter();
    // },
    getIndex(index) {
      this.index = index;
      this.$set(this.tableData, index, []);
      // this.CLEAR_FILTER('workreport');
      this.filterData = {}
      const cleard = debounce(this.clear, 500);
      cleard();
    },
    clear() {
      for (const i in this.requestData) {
        this.requestData[i] = '';
      }
      this.requestData.pageNum = 1;
      this.requestData.pageSize = 10;
      this.tableData[this.index] = [];
      this.refreshLoad();
    },
    init() {
      this.getGetters();
      this.tabList = [];
      this.aidList.forEach((item, i) => {
        this.tabList.push({ label: item.mlabel, value: i, code: i, aidList: item.actions, num: '' });
        item.actions.forEach(itemA => {
          switch (itemA.acode) {
            case 'query':
              this.aid.push(itemA.aid);
              break;
          }
        });
        this.getLists(this.requestData, this.aid, i, item.mlabel);
      });
    },
    fromFilter() {
      const i = this.index;
      this.getGetters();
      this.requestData.pageNum = 1;
      this.getLists(this.requestData, this.aid, i, this.aidList[i].mlabel);
    },
    // 获取vuex数据
    getGetters() {
      // for (const i in this.filterObj) {
      //   this.requestData[i] = this.filterObj[i].value || '';
      // }
    },
    refreshLoad(val, type) {
      const i = this.index;
      this.getLists({ ...this.requestData, clueName: val }, this.aid, i, this.aidList[i].mlabel, type);
    },
    tabChange() {
      this.conditionValue.fastItem = { code: '' }
      this.getIndex(this.tabItem.code)
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    fastChange() {
      if (this.isMineTab(this.tabItem.code)) {
        this.requestData.status = `${this.fastItem.code}`
      } else if (this.isBranchTab(this.tabItem.code)) {
        this.requestData.readFlag = `${this.fastItem.code}`
      }
      this.requestData.pageNum = 1
      this.fromFilter()
      this.$nextTick(() => {
        this.$refs.complex.allRefresh()
      })
    },
    // 下拉刷新
    onPullingDown() {
      this.requestData.pageNum = 1
      this.refreshLoad('', 'refresh')
    },
    // 上拉加载
    onPullingUp() {
      this.requestData.pageNum++
      this.refreshLoad('', 'upload')
    },
    diffChange(diff) {
      if (diff > 1) {
        this.scrollDiff = 1
      } else {
        this.scrollDiff = diff
      }
    },
    isMineTab(code) {
      const item = this.tabList[code]
      return item && (item.label.includes('我提交'))
    },
    isBranchTab(code) {
      const item = this.tabList[code]
      return item && (item.label.includes('提交给我'))
    },
    filterConfirm() {
      this.setRequestData()
      const i = this.index;
      this.requestData.pageNum = 1;
      this.requestData.pageSize = 10;
      this.getLists(this.requestData, this.aid, i, this.aidList[i].mlabel);
      this.filterModelShow = false
    },
    setRequestData() {
      for (const key in this.filterData) {
        if (['createTime'].includes(key)) {
          this.requestData.startDate = this.filterData[key][0] || ''
          this.requestData.endDate = this.filterData[key][1] || ''
        } else {
          this.requestData[key] = this.filterData[key]
        }
      }
    }
  }
};
</script>

<style  lang="scss"  scoped>
.complex-header{
  width:100%;
  height: 58px;
  overflow: hidden;
  background-color: #fff;
  box-shadow: none;
}
.errorHeight{
  height:calc(100vh - 118px);
}
.search_btn{
  position: fixed;
  right: 10px;
  bottom: 42px;
  z-index: 99;
  width: 56px;
  height: 56px;
  background-image: url('../../assets/img/btn_shaixuan.png');
  background-position:center;
  background-size: 56px 56px;
}
.dialog-detail{
  z-index: 102;
  height: calc(90vh - 61px);
  overflow-y: auto;
  .dialog-content{
    margin: 14px 15px 22px 15px;
    .dialog-content-title{
      margin-bottom: 14px;
      color: $color-999;
      font-size: $font-12;
      text-align: left;
    }
  }
  .dialog-content:last-child{
    margin: 14px 15px 0 15px;
  }
}
.card-time{
  padding-top: 15px;
  padding-bottom: 8px;
  padding-left: 20px;
  color: #999;
  font-weight: 400;
  font-size: $font-14;
  line-height: 20px;
  text-align: left;
  background-color: #fff;
}
</style>
